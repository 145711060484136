<template>
  <div className="animated fadeIn">

      <button v-show="hasAccess" @click="downloadCSV" class="btn btn-md btn-outline-dark float-right" style="margin-top: 20px; right: 100px; z-index: 2000; position: absolute;" >Export data</button>
      <span class="clearfix"></span>

      <v-server-table :columns="columns" :data="data" :options="options" :theme="theme" id="dataTable" class="table">

          <template slot="actions" slot-scope="props">
            <b-button v-show="hasFullAccess" variant="secondary" :to="{ name: 'GDPRAudit', params: { id: props.row.id } }">View</b-button>
          </template>

      </v-server-table>
  </div>
</template>

<script>
  import Vue from 'vue'
  import {ServerTable} from 'vue-tables-2'
  import axios from '../../../shared/axios/auth'

  Vue.use(ServerTable)

  export default {
    name: 'DataTable',
    components: {
      ServerTable
    },
    data: function () {
      return {
        get_document: false,
        columns: [
          'audit_type', 'description', 'number', 'created', 'actions'
        ],
        data: [],
        checkedData: [],
        fields: [
        ],
        options: {
          requestFunction: (data) => {
              return axios.get('/gdpr-audits/', {
                  params: data
              }).catch(error => {
                // Unauthorised
                if (error.response.status === 401) {
                  this.$store.dispatch('logout');
                }
                console.log('gdpr audit error');
                console.log(error.response)
              });
          },
          responseAdapter: function(resp) {
            var data = this.getResponseData(resp);
            return { data: data.data.data, count: data.data.pagination.total }
          },
          headings: {
            audit_type: 'Type'
          },
          sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
        },
        useVuex: false,
        theme: 'bootstrap4',
        template: 'default'
      }
    },
    methods: {
      downloadCSV() {

        this.get_document = true;
        let url = '';

        if (localStorage.getItem('selected_product')) {
          url = '/dpr-audits/export/?product=' + localStorage.getItem('selected_product');
        } else {
          url = '/dpr-audits/export/';
        }

        axios({
          url: url,
          method: 'GET',
          responseType: 'blob', // important
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', "gdpr-export.csv");
          document.body.appendChild(link);
          link.click();
          this.get_document = false;
        }).catch(error => {

              // Unauthorised
              if (error.response.status === 401) {
                this.$store.dispatch('logout');
              }
              console.log('post error');
              console.log(error.response);
          });
      },
      getAudits() {
        let url = '';

        if (localStorage.getItem('selected_product')) {
          url = '/gdpr-audits/?product=' + localStorage.getItem('selected_product');
        } else {
          url = '/gdpr-audits/';
        }
        axios.get(url).then(
          response => (
            this.data = response.data
          )
        ).catch(error => {

              // Unauthorised
              if (error.response.status === 401) {
                this.$store.dispatch('logout');
              }
              console.log('post error');
              console.log(error.response);
          });
        return this.data
      },
    },
    computed: {
      hasAccess() {

        let access = ['full_admin', 'data_management'];

        let allowed = false;

        access.forEach(item => {
          if (this.$store.getters.getUserPermissions.includes(item)) {
            allowed = true
          }
        })

        return allowed

      },
      hasFullAccess() {

        let access = ['full_admin',];

        let allowed = false;

        access.forEach(item => {
          if (this.$store.getters.getUserPermissions.includes(item)) {
            allowed = true
          }
        })

        return allowed

      }
    },
    watch: {
      $route(to, from) {
        // react to route changes...
        this.$router.replace(to.fullPath).catch(error => {
          console.log(error)
        }).then(
          this.getAudits()
        );

      }
    },
    mounted() {
      this.getAudits()
    }

  };
</script>

<style>

  #dataTable  table {
    background-color: #fff;
  }
  .form-inline label {
      justify-content: left;

  }


</style>
